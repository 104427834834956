import { update } from '@react-spring/web';
import { hasValue } from '../numberUtils';
import { deepClone } from '../objectUtils';
import { truncateObjectString } from '../stringUtils';
let storage = { lastUpdate: Date.now() };
updateStorageCapacity(Date.now());

export function getCustomEventString(e) {
  return (
    `${e.type}-EV:` +
    ` ${getAnonymDataStr(e)}|${getTransacStr(e)}|${getUserStr(
      e
    )}|${e.result.toUpperCase()}` +
    `|${e.browser}|${e.connection}`
  );
}
export function getAnonymDataStr(e) {
  return e.data ? JSON.stringify(anonymizeData(e.data)) : '';
}

export function getEndpointStr(e) {
  const pathParamsStr =
    e.endpointParams && e.endpointParams.length ? ` P:${e.endpointParams}` : '';
  const queryStr = e.params ? ` Q:${JSON.stringify(e.params)}` : '';
  const anonymBodyStr = e.data
    ? ` B:${truncateObjectString(JSON.stringify(anonymizeData(e.data)))}`
    : '';
  return (
    `${e.method?.toUpperCase()} ${e.endpoint}` +
    `${pathParamsStr}${queryStr}${anonymBodyStr}`
  );
}

export function getTransacStr(e) {
  return (
    `${e.transactionId ?? 'custom-event'}|${e.env}|WEB` +
    `|${e.date}(${e.timeZoneOffset})`
  );
}

export function getElapsedStr(e) {
  const responseTime = Date.now();
  const elapsedTime = responseTime - e.requestTime;
  return `${elapsedTime}ms`;
}

export function getUserStr(e) {
  return e.userId == 'none' ? 'none' : `${e.userType}:${e.userId}`;
}

export function getErrorStr(e) {
  return `${e.result}(${e.status});${e.body.error}:${e.body.message};${e.error.message}`;
}

export function getDeviceInfoStr() {
  if (!(navigator?.hardwareConcurrency && navigator?.deviceMemory))
    return '0-0';
  return `${navigator.hardwareConcurrency}-${navigator.deviceMemory}`;
}

export function getLanguageStr() {
  return navigator?.language || '-';
}

export function getConnectionInfoStr() {
  const netStats =
    navigator?.connection ||
    navigator?.mozConnection ||
    navigator?.webkitConnection;
  return netStats
    ? `${netStats.downlink}-${netStats.effectiveType}-${netStats.rtt}-${netStats.saveData}`
    : '---';
}

export function getStorageStr() {
  const timeStamp = Date.now();
  if (timeStamp - storage.lastUpdate > 1000 * 60 * 5) {
    updateStorageCapacity(timeStamp);
  }
  if (!storage.error) {
    if (hasValue(storage.usage) && hasValue(storage.quota)) {
      return `${((100 * storage.usage) / storage.quota).toFixed(2)}%(${(
        storage.quota /
        1024 /
        1024 /
        1024
      ).toFixed(2)}GB)`;
    }
    return 'waiting...';
  }
  return 'unknown';
}

async function updateStorageCapacity(timeStamp) {
  let storageEstimate;
  try {
    storageEstimate = await navigator?.storage?.estimate();
    storage = {
      quota: storageEstimate.quota ?? 0,
      usage: storageEstimate.usage ?? 0,
      lastUpdate: timeStamp,
    };
  } catch (err) {
    storage.error = true;
  }
}

export function anonymizeData(data) {
  const forbiddenKeys = [
    'password',
    'newPassword',
    'currentPassword',
    'email',
    'login',
    'username',
    'userName',
    'firstName',
    'lastName',
  ];
  if (!data) return {};
  const clonedData = deepClone(data);
  const hasForbiddenKey = forbiddenKeys.some((key) => clonedData[key]);
  if (!hasForbiddenKey) {
    return clonedData;
  } else {
    for (const key in clonedData) {
      if (forbiddenKeys.includes(key)) {
        clonedData[key] = '***';
      }
    }
    return clonedData;
  }
}
