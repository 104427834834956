import getOnGoingRealTimeActivity from '@/api/realTime/getOnGoingRealTimeActivity.endpoint';
import getRealTimeActivity from '@/api/realTime/getRealTimeActivity.endpoint';
import useCustomHook from '@/hooks/useCustomHook';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef } from 'react';
import openAPIErrorNotification from '../APIErrorNotification/APIErrorNotification';
import { GlobalContext } from '../GlobalContext/GlobalContext';
import useRealTimeServer from '@/hooks/realTime/useRealTimeServer.hook';

export default function useStudentRealTimeActivityContext() {
  const globalContext = useContext(GlobalContext);
  const studentId =
    globalContext.state.user?.student?.genericStudentId ||
    globalContext.state.user?.student?.id;
  const studentName = `${globalContext.state.user?.student?.firstName} ${globalContext.state.user?.student?.lastName}`;
  const router = useRouter();

  const serverActionsHandlerRef = useRef();
  const { state: serverState, actions: serverActions } = useRealTimeServer({
    actionsHandler: serverActionsHandlerRef.current,
  });

  const { state, actions } = useCustomHook({
    defaultState: {
      realTimeActivity: null,
      realTimeActivityId: null,
      progress: null,
      isNameNeeded: false,
      name: null,
    },
    actions: {
      redirect: (realTimeActivity) => {
        if (globalContext.state.user?.student) {
          let route = null;

          if (realTimeActivity?.status == 'running') {
            route = '/student/liveTask';
          } else if (router.pathname.includes('/liveTask')) {
            route = '/student/dashboard';
          }
          if (route) {
            router.push(route);
          }
        }
      },
      loadRealTimeActivity: async () =>
        await loadRealTimeActivity({ state, actions }),
      checkExistingRealTimeActivity: async () =>
        await checkExistingRealTimeActivity({ actions }),
      publicActions: {
        setRealTimeActivityId: (realTimeActivityId) => {
          actions.setState({
            realTimeActivityId,
          });
        },
        setName: (name) => {
          actions.setState({
            name,
          });
        },
        join: async () => {
          serverActions.reset();
          await serverActions.sendMessage({
            action: 'join',
            data: {
              realTimeActivityId: state.realTimeActivityId,
              studentId,
              name: state.name || studentName,
            },
          });
        },
        leave: async () => {
          serverActions.sendMessage({
            action: 'leave',
            data: {
              realTimeActivityId: state.realTimeActivityId,
              studentId,
            },
          });
        },
        updateStudent: async (column, value) => {
          actions.setState({ [column]: value });
          await serverActions.sendMessage({
            action: 'updateStudent',
            data: {
              realTimeActivityId: state.realTimeActivityId,
              studentId,
              column,
              value,
            },
          });
        },
        loadServerStudentActivity: async () => {
          await serverActions.sendMessage({
            action: 'requestStudentActivity',
            data: {
              realTimeActivityId: state.realTimeActivityId,
              studentId,
            },
          });
        },
      },
    },
  });

  serverActionsHandlerRef.current = {
    start: async ({ realTimeActivityId }) => {
      actions.setState({ realTimeActivityId });
    },
    stop: async () => {
      actions.resetState({
        loaded: true,
      });
      actions.redirect();
    },
    studentUpdated: async (studentItem) => {
      actions.setState({ ...studentItem });
    },
  };

  useEffect(() => {
    if (globalContext.state.loaded) {
      if (globalContext.state.user?.student) {
        if (
          state.realTimeActivityId &&
          (!state.realTimeActivity ||
            state.realTimeActivity?.id != state.realTimeActivityId)
        ) {
          actions.loadRealTimeActivity();
        } else if (!state.realTimeActivityId) {
          actions.checkExistingRealTimeActivity();
        } else {
          actions.setState({ loaded: true });
        }
      } else {
        serverActions.reset();
        actions.resetState({
          loaded: true,
        });
      }
    }
  }, [
    globalContext.state.loaded,
    globalContext.state.user,
    state.realTimeActivityId,
  ]);

  useEffect(() => {
    if (state.realTimeActivity?.data?.internalUseClass) {
      actions.setState({ isNameNeeded: !state.name });
    }
  }, [state.name]);

  return {
    state: {
      ...state,
      serverState,
    },
    actions: actions.publicActions,
  };
}

async function loadRealTimeActivity({ state, actions }) {
  try {
    const realTimeActivity = await getRealTimeActivity(
      state.realTimeActivityId
    );
    actions.setState({
      realTimeActivity,
      isNameNeeded: !!realTimeActivity?.data?.internalUseClass,
      loaded: true,
    });
    actions.redirect(realTimeActivity);
  } catch (e) {
    console.error(e);
    actions.setState({
      loaded: true,
    });
    openAPIErrorNotification({
      title: 'There was an error with the LIVE task',
      description: 'There was a problem loading the LIVE task',
      errorFeedback: e.feedbackData,
    });
  }
}

async function checkExistingRealTimeActivity({ actions }) {
  try {
    const realTimeActivity = await getOnGoingRealTimeActivity();
    if (realTimeActivity) {
      actions.setState({
        realTimeActivity,
        isNameNeeded: !!realTimeActivity?.data?.internalUseClass,
        realTimeActivityId: realTimeActivity.id,
        loaded: true,
      });
    } else {
      actions.setState({
        loaded: true,
      });
    }
    actions.redirect(realTimeActivity);
  } catch (e) {
    console.error(e);
    actions.setState({
      loaded: true,
    });
  }
}
