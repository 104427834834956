import appStorage from '@/modules/storage/appStorage';
import { useState, useEffect } from 'react';
export default function useLocalStorageContextHook({
  contextName,
  defaultState,
  actions: customActions,
}) {
  const [state, setState] = useState({ loaded: false, ...defaultState });
  const actions = {
    setState: (newState) => {
      setState((previousState) => {
        const newCombinedState = { ...previousState, ...newState };
        appStorage('localStorage').setItem(
          contextName,
          JSON.stringify(newCombinedState)
        );
        return newCombinedState;
      });
    },
    ...customActions,
  };

  useEffect(() => {
    const savedState = appStorage('localStorage').getItem(contextName);
    if (savedState) {
      const savedStateObject = JSON.parse(savedState);
      actions.setState({ ...savedStateObject, loaded: true });
    } else {
      actions.setState({ loaded: true });
    }
  }, []);

  return { state, actions };
}
