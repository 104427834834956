const SSOConf = {
  clever: {
    clientId: process.env.NEXT_PUBLIC_CLEVER_CLIENT_ID,
    redirectUri: `${process.env.NEXT_PUBLIC_API}/auth/clever`,
    getLoginUrl: ({ user, redirectTo, redirectErrorTo }) => {
      const redirectUri = encodeURIComponent(SSOConf.clever.redirectUri);
      const state = getStateParam({ user, redirectTo, redirectErrorTo });
      return `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirectUri}&client_id=${SSOConf.clever.clientId}&state=${state}`;
    },
  },
  google: {
    clientId:
      '420226320333-dbtobr1e55jd4gqhjha6v43ikv523eif.apps.googleusercontent.com',
    redirectUri: `${process.env.NEXT_PUBLIC_API}/auth/google`,
    getLoginUrl: ({
      user,
      redirectTo,
      redirectErrorTo,
      isStudent,
      moreScopes = [],
      skippable,
    }) => {
      const scopes = ['profile', 'email', ...moreScopes];
      if (!isStudent) {
        scopes.push(
          'https://www.googleapis.com/auth/classroom.courses.readonly',
          'https://www.googleapis.com/auth/classroom.rosters.readonly',
          'https://www.googleapis.com/auth/classroom.coursework.students',
          'https://www.googleapis.com/auth/classroom.push-notifications'
        );
      }
      const scopesParam = encodeURIComponent(scopes.join(' '));
      const redirectUri = SSOConf.google.redirectUri;
      const state = getStateParam({
        user,
        redirectTo,
        redirectErrorTo,
        extraParams: {
          isStudent,
          skippable,
        },
      });
      let url = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scopesParam}&access_type=offline&include_granted_scopes=true&response_type=code&state=${state}&redirect_uri=${redirectUri}&client_id=${SSOConf.google.clientId}`;
      const email = getGoogleEmail(user);
      if (email) {
        url += `&login_hint=${email}`;
      }
      return url;
    },
  },
  classlink: {
    clientId: process.env.NEXT_PUBLIC_CLASSLINK_CLIENT_ID,
    redirectUri: `${process.env.NEXT_PUBLIC_API}/auth/classlink`,
    scopes: ['full', 'profile', 'oneroster', 'openid'],
    getLoginUrl: ({ user, redirectTo, redirectErrorTo }) => {
      const redirectUri = encodeURIComponent(SSOConf.classlink.redirectUri);
      const state = getStateParam({ user, redirectTo, redirectErrorTo });
      return `https://launchpad.classlink.com/oauth2/v2/auth?scope=${SSOConf.classlink.scopes.join(
        ','
      )}&redirect_uri=${redirectUri}&client_id=${
        SSOConf.classlink.clientId
      }&response_type=code&state=${state}`;
    },
  },
};

function getStateParam({
  user,
  redirectTo,
  redirectErrorTo,
  extraParams = {},
}) {
  const state = {
    teacherId: user?.teacher?.id,
    studentId: user?.student?.id,
    redirectTo,
    redirectErrorTo,
    timeOffset: new Date().getTimezoneOffset(),
    ...extraParams,
  };
  return encodeURIComponent(JSON.stringify(state));
}

function getGoogleEmail(user) {
  const userByType = user?.student || user?.teacher;
  return userByType?.ssoProviders?.find(
    (ssoProvider) => ssoProvider.provider == 'google'
  )?.data?.emailAddresses?.[0]?.value;
}

export default SSOConf;
