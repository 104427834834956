import { useState } from 'react';
import useLocalStorageContextHook from '/src/hooks/useLocalStorageContextHook';

const contextName = 'sidebarContext';

export default function useSidebarContext() {
  const [menuItems, setMenuItems] = useState([]);
  const { state, actions } = useLocalStorageContextHook({
    contextName,
    defaultState: {
      collapsed: false,
      selected: '/',
    },
    actions: {
      publicActions: {
        setCollapsed: (collapsed) => {
          actions.setState({
            collapsed,
          });
        },
        setSelected: (selected) => {
          actions.setState({
            selected,
          });
        },
        setMenuItems,
      },
    },
  });

  return {
    state: { ...state, menuItems },
    actions: actions.publicActions,
  };
}
