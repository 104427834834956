import useLocalStorageContextHook from '@/hooks/useLocalStorageContextHook';
import { getBroadcastChannel } from '@/modules/broadcastChannel/broadcastChannel';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { globalContextChannel } from '../GlobalContext/useGlobalContext.hook';
import { GlobalContext } from '../GlobalContext/GlobalContext';
import { usePreviousRoute } from '@/hooks/usePreviousRoute';

export default function useNavigationContext() {
  const router = useRouter();
  const globalContext = useContext(GlobalContext);
  const previousRoute = usePreviousRoute();

  const { state, actions } = useLocalStorageContextHook({
    contextName: 'navigationContext',
    defaultState: {
      classes: [],
      returnUrl: null,
      pagesData: {},
      urlAfterLogin: null,
      helpContext: null,
    },
    actions: {
      publicActions: {
        setClasses: (classes, helpContext) => {
          actions.setState({
            classes,
            helpContext,
          });
        },
        goTo: (to) => {
          actions.setState({
            returnUrl: router.asPath,
          });
          router.push(to);
        },
        goBack: () => {
          const { returnUrl } = state;
          actions.setState({
            classes: [],
            returnUrl: null,
          });
          router.push(returnUrl);
        },
        setPageData: (pageName, pageData) => {
          const currentPageData = state.pagesData[pageName] || {};
          const pagesData = {
            ...state.pagesData,
            [pageName]: { ...currentPageData, ...pageData },
          };
          actions.setState({ pagesData });
        },
        setUrlAfterLogin(to) {
          actions.setState({ urlAfterLogin: to });
        },
        redirectAfterLogin() {
          let urlAfterLogin = state.urlAfterLogin;
          const isTeacher = globalContext.state.user?.teacher;
          const isStudent = globalContext.state.user?.student;
          actions.setState({ urlAfterLogin: null });
          if (isTeacher) {
            router.push(urlAfterLogin);
            return;
          } else if (isStudent) {
            router.push(
              urlAfterLogin
                .replace('/library/activities', '/student/activitiesBySlug')
                .replace('/library', '/student')
            );
            return;
          }
        },
      },
    },
  });

  useEffect(() => {
    function onGlobalContextMessage(data) {
      if (data.message == 'LOGOUT') {
        actions.setState({
          classes: [],
          returnUrl: null,
        });
      }
    }

    const channel = getBroadcastChannel(globalContextChannel);
    channel.addEventListener('message', onGlobalContextMessage);
    return () => {
      channel.addEventListener('message', onGlobalContextMessage);
    };
  }, []);

  useEffect(() => {
    if (
      !router.pathname.includes('/themes') &&
      !router.pathname.includes('/library') &&
      !router.pathname.startsWith('/auth/callback')
    ) {
      const pagesData = resetSearchText(state.pagesData);
      actions.setState({ classes: [], pagesData });
    }
  }, [router.pathname]);

  return {
    state: {
      ...state,
      previousRoute,
    },
    actions: actions.publicActions,
  };
}

function resetSearchText(pagesData) {
  return Object.entries(pagesData).reduce((acc, [pageName, pageData]) => {
    console.log(pageName, pageData);
    acc[pageName] = pageData.searchText
      ? { ...pageData, searchText: '' }
      : pageData;
    return acc;
  }, {});
}
