import axios from '@/api/axiosInstance';

export default async function getThemes(includeActivities = false) {
  const urlName = includeActivities ? 'themesWithActivities' : 'themes';
  const suffix = process.env.NEXT_PUBLIC_THEME_CACHE_SUFFIX || '';
  let response = null;
  try {
    response = await axios({
      method: 'get',
      url: `${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_HOST}/cache/${process.env.NEXT_PUBLIC_ROCKALINGUA_ENVIRONMENT}/${urlName}${suffix}.json`,
      params: { v: Date.now() },
    });
  } catch (e) {
    response = await axios({
      method: 'get',
      url: `${
        process.env.API || process.env.NEXT_PUBLIC_API
      }/themes?includeActivities=${includeActivities}`,
    });
  }
  return response.data;
}
