import { notification } from 'antd';
import {
  StyledAjaxErrorNotigicationMessage,
  StyledAjaxErrorNotigicationDescription,
} from './APIErrorNotification.styled';
import * as Sentry from '@sentry/nextjs';
import appStorage from '@/modules/storage/appStorage';
import Button from '../Button/Button';

export default function openAPIErrorNotification({
  title,
  description,
  errorFeedback = {},
  notify401 = false,
  needSync = false,
}) {
  if (errorFeedback?.httpStatus == 401 && !notify401) {
    return;
  }
  console.log(
    description,
    Array.isArray(description),
    Array.isArray(description) && description.length > 0
  );

  if (needSync) {
    errorFeedback.message = (
      <div>
        Please click on{' '}
        <Button
          href="/classes?action=importClassesWizard"
          size="small"
          type="primary"
        >
          <strong>Sync</strong>
        </Button>{' '}
        to continue
      </div>
    );
  }

  notification.error({
    style: { width: '50vw' },
    message: (
      <StyledAjaxErrorNotigicationMessage>
        {title}
      </StyledAjaxErrorNotigicationMessage>
    ),
    description: (
      <StyledAjaxErrorNotigicationDescription>
        <div className="description">
          {Array.isArray(description) && description.length > 0 ? (
            description.map((phrase, index) => {
              return (
                <div key={index}>
                  <strong>{phrase}</strong>
                </div>
              );
            })
          ) : (
            <strong>{description}</strong>
          )}
        </div>
        <div className="description">{errorFeedback.message}</div>
        {/* {errorFeedback.url ? (
          <div className="error">
            <div className="error-prop problem">
              <div className="label">Problem:</div>
              <div className="value">{errorFeedback.problem}</div>
            </div>
            <div className="error-prop url">
              <div className="label">Url:</div>
              <div className="value">
                {errorFeedback.method &&
                  `(${errorFeedback.method.toUpperCase()}) `}
                {errorFeedback.url}
              </div>
            </div>
            {errorFeedback.httpStatus && (
              <div className="error-prop status">
                <div className="label">HTTP status:</div>
                <div className="value">{errorFeedback.httpStatus}</div>
              </div>
            )}
          </div>
        ) : null} */}
      </StyledAjaxErrorNotigicationDescription>
    ),
    duration: 0,
  });

  try {
    const globalContextLocalStorage =
      appStorage('localStorage')?.getItem('globalContext');
    if (errorFeedback?.message == 'Network Error') {
      Sentry.captureException(
        new Error(
          `${title != 'There was an error' ? title : ''} ${description} (${
            errorFeedback.message
          })`
        ),
        {
          extra: {
            title,
            description,
            errorFeedback,
            globalContext: globalContextLocalStorage,
            frontUrl: window.location.href,
          },
        }
      );
    }
  } catch (e) {
    console.error(e);
  }
}
