import { createContext, useRef } from 'react';
import useAudioAutoPlayerContext from './useAudioAutoPlayerContext.hook';

export const AudioAutoPlayerContext = createContext({});

export function AudioAutoPlayerContextProvider({ children }) {
  const playerRef = useRef(null);
  const { state, actions } = useAudioAutoPlayerContext(playerRef);
  return (
    <AudioAutoPlayerContext.Provider value={{ state, actions, playerRef }}>
      {children}
    </AudioAutoPlayerContext.Provider>
  );
}
