import { createContext } from 'react';
import useFullscreenContext from './useFullscreenContext.hook';

export const FullscreenContext = createContext({});

export function FullscreenContextProvider({ children }) {
  const { state, actions } = useFullscreenContext();
  return (
    <FullscreenContext.Provider value={{ state, actions }}>
      {children}
    </FullscreenContext.Provider>
  );
}
