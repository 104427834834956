import { useContext, useEffect, useRef } from 'react';
import useCustomHook from '@/hooks/useCustomHook';
import { WebsocketsContext } from '@/shared/WebsocketsContext/WebsocketsContext';

export default function useRealTimeServer({ actionsHandler }) {
  const websocketsContext = useContext(WebsocketsContext);
  const prevTimestampByAction = useRef({});

  const { state, actions } = useCustomHook({
    defaultState: {},
    actions: {
      reset: () => {
        prevTimestampByAction.current = {};
        actions.resetState();
      },
      sendMessage: async ({ action, data }) => {
        await websocketsContext.actions.sendMessage({
          action,
          data,
        });
      },
    },
  });

  useEffect(() => {
    if (
      websocketsContext.state.connectionKey &&
      websocketsContext.state.lastMessage
    ) {
      const { action, data, timestamp } = websocketsContext.state.lastMessage;
      const prevTimestamp = prevTimestampByAction.current[action] || 0;
      if (timestamp >= prevTimestamp) {
        prevTimestampByAction.current[action] = timestamp;
        if (action == 'activityChanged') {
          actions.setState(data);
        } else if (actionsHandler) {
          actionsHandler[action]?.(data);
        }
      }
    }
  }, [
    websocketsContext.state.connectionKey,
    websocketsContext.state.lastMessage,
  ]);

  return { state, actions };
}
