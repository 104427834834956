import bowser from 'bowser';
import jsonwebtoken from 'jsonwebtoken';
//import bowser from 'bowser/bundled';

const slugEndpoints = ['/themes/fromSlug/', '/blog/', '/activities/fromSlug/'];
let browserInfo;
let user;

export function getEndpoint(url) {
  let slugEndpointMatch = null;
  let endpoint;
  let parameters;
  const isSlugEndpoint = slugEndpoints.some((slugEndPoint) => {
    if (url.startsWith(slugEndPoint)) {
      slugEndpointMatch = slugEndPoint;
      return true;
    }
  });
  if (isSlugEndpoint) {
    const segments = url.split(slugEndpointMatch);
    endpoint = slugEndpointMatch;
    parameters = [segments[1]];
  } else {
    const paramRegex = /\/(\d+)/g;
    parameters = [];
    endpoint = url.replace(paramRegex, (_, param) => {
      parameters.push(param);
      return `/[id${parameters.length}]`;
    });
  }
  return { endpoint, endpointParams: parameters };
}

export function updateUser(token) {
  user = token ? jsonwebtoken.decode(token) : null;
}

export function getUserType() {
  return (
    (user?.teacher && 't') ||
    (user?.student && 's') ||
    (user?.admin && 'a') ||
    'n'
  );
}

export function getUserId() {
  return user?.teacher?.id ?? user?.student?.id ?? user?.admin?.id ?? 'none';
}

function getBrowserInfo() {
  if (!browserInfo) {
    const userAgent = navigator.userAgent;
    browserInfo = bowser.parse(userAgent);
  }
  return browserInfo;
}

export function getBrowserString() {
  const browser = getBrowserInfo();
  return `${browser.browser.name}-${browser.browser.version}-${
    browser.platform.type
  }-${browser.os.name}-${browser.os.version ?? ''}`;
}
