import useLocalStorageContextHook from '/src/hooks/useLocalStorageContextHook';
import getStudentClasses from '@/api/studentDashboard/getStudentClasses.endpoint';
import openAPIErrorNotification from '@/shared/APIErrorNotification/APIErrorNotification';
import { GlobalContext } from '@/shared/GlobalContext/GlobalContext';
import { useEffect, useContext } from 'react';

export default function useStudentContext() {
  const globalContext = useContext(GlobalContext);
  const { state, actions } = useLocalStorageContextHook({
    contextName: 'studentContext',
    defaultState: {
      classes: null,
      currentClass: null,
    },
    actions: {
      loadClasses: async () => {
        actions.setState({ loaded: false });
        try {
          const studentRawClasses = await getStudentClasses();
          const studentClasses = studentRawClasses
            .sort((a, b) => b.createdAt < a.createdAt)
            .map((stdClass) => {
              console.log(stdClass);
              const stdClassDates = [...stdClass.classStudents].pop();
              const teacher = stdClass.subscription.teacher;
              return {
                name: stdClass.name,
                id: stdClass.id,
                teacher: `${teacher.firstName} ${teacher.lastName}`,
                movedAt: stdClassDates.movedAt,
                createdAt: stdClassDates.createdAt,
                internalUse: stdClass.internalUse,
              };
            });
          const currentClass =
            state.currentClass &&
            studentClasses.some((clazz) => clazz.id == state.currentClass)
              ? state.currentClass
              : studentClasses?.length == 1
              ? studentClasses[0].id
              : null;

          actions.setState({
            classes: studentClasses,
            loaded: true,
            currentClass,
          });
        } catch (error) {
          actions.setState({ loaded: true });
          openAPIErrorNotification({
            title: 'There was an error',
            description: 'There was a problem loading the student class(es)',
            errorFeedback: error.feedbackData,
          });
        }
      },
      publicActions: {
        setCurrentClass: (classId) => {
          actions.setState({
            currentClass: classId,
          });
        },
        forceClassesReload: async () => {
          await actions.loadClasses();
        },
      },
    },
  });

  useEffect(() => {
    if (globalContext.state.loaded) {
      if (globalContext.state.user?.student) {
        if (!state.classes) {
          actions.loadClasses();
        }
      } else {
        actions.setState({ loaded: false, classes: null, currentClass: null });
      }
    }
  }, [globalContext.state.loaded, globalContext.state.user, state.classes]);

  useEffect(() => {
    if (state.classes && state.currentClass) {
      const internalUseClass = state.classes.find((clazz) => clazz.internalUse);
      actions.setState({
        isSharedStudent: internalUseClass?.id == state.currentClass,
      });
    }
  }, [state.currentClass, state.classes]);

  return { state, actions: actions.publicActions };
}
