import getThemes from '@/api/library/getThemes.endpoint';
import useCustomHook from '@/hooks/useCustomHook';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../GlobalContext/GlobalContext';

const hour = 3600000;

export default function useThemesContext() {
  const globalContext = useContext(GlobalContext);
  const { state, actions } = useCustomHook({
    defaultState: {
      loaded: false,
      themes: [],
    },
    actions: {
      loadThemes: async () => {
        try {
          const themes = await getThemes(true);
          actions.setState({
            themes,
            loaded: true,
          });
        } catch (e) {
          console.error(e);
          actions.setState({ loaded: true });
        }
      },
      publicActions: {},
    },
  });

  useEffect(() => {
    if (globalContext.state.loaded /* && globalContext.state.user */) {
      actions.loadThemes();
    }
  }, [globalContext.state.loaded, globalContext.state.user]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (globalContext.state.loaded /* && globalContext.state.user */) {
        actions.loadThemes();
      }
    }, hour);
    return () => clearInterval(interval);
  }, []);

  return { state, actions: actions.publicActions };
}
