const HexOpacities = {
  0: '00',
  10: '1A',
  20: '33',
  30: '4D',
  40: '66',
  50: '80',
  60: '99',
  70: 'B3',
  80: 'CC',
  90: 'E6',
  100: 'FF',
};

export default HexOpacities;
