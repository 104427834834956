import { createContext } from 'react';
import useStudentContext from './useStudentContext.hook';

export const StudentContext = createContext();

export function StudentContextProvider({ children }) {
  const { state, actions } = useStudentContext();
  return (
    <StudentContext.Provider value={{ state, actions }}>
      {children}
    </StudentContext.Provider>
  );
}
