import React from 'react';
import { Button, Result } from 'antd';
import * as Sentry from '@sentry/nextjs';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    Sentry.captureException(error);
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="500"
          title="Oops"
          subTitle="Sorry, something went wrong."
          extra={
            <Button
              type="primary"
              onClick={() => {
                window.location.reload();
                this.setState({ hasError: false });
              }}
            >
              Try again
            </Button>
          }
        />
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}
