import useCustomHook from '@/hooks/useCustomHook';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../GlobalContext/GlobalContext';
import useWebSocket from 'react-use-websocket';
import { StudentContext } from '../StudentContext/StudentContext';
const websocketsApi = process.env.NEXT_PUBLIC_WS_API;

export default function useWebsocketsContext() {
  const globalContext = useContext(GlobalContext);
  const studentContext = useContext(StudentContext);
  const [socketUrl, setSocketUrl] = useState(null);
  const [connectionKey, setConnectionKey] = useState(null);

  const { lastJsonMessage, sendJsonMessage } = useWebSocket(socketUrl, {
    onOpen: () => {
      const newConnectionKey = Date.now();
      setConnectionKey(newConnectionKey);
      console.log('connected', newConnectionKey);
    },
    shouldReconnect: () => true,
    retryOnError: true,
    reconnectAttempts: 10,
  });

  const { state, actions } = useCustomHook({
    defaultState: {
      loaded: false,
    },
    actions: {
      publicActions: {
        sendMessage: sendJsonMessage,
      },
    },
  });

  useEffect(() => {
    if (globalContext.state.loaded) {
      let newSocketUrl = null;
      if (globalContext.state.user) {
        const { user } = globalContext.state;
        const userType = getUserType(globalContext.state.user);
        const userId = `${getUserId(user)}`;

        if (userType == 'teacher') {
          newSocketUrl = `${websocketsApi}?userId=${userId}&userType=${userType}`;
        } else if (
          userType == 'student' &&
          studentContext.state.loaded &&
          studentContext.state.currentClass
        ) {
          newSocketUrl = `${websocketsApi}?userId=${userId}&userType=${userType}&classId=${studentContext.state.currentClass}`;
        }
      }
      if (newSocketUrl != socketUrl) {
        setSocketUrl(newSocketUrl);
      }
    }
  }, [
    globalContext.state.loaded,
    globalContext.state.user,
    studentContext.state.loaded,
    studentContext.state.currentClass,
  ]);

  return {
    state: { ...state, connectionKey, lastMessage: lastJsonMessage },
    actions: actions.publicActions,
  };
}

export function getUserType(user) {
  let type = '';
  if (user?.student) {
    type = 'student';
  } else if (user?.teacher) {
    type = 'teacher';
  } else if (user?.admin) {
    type = 'admin';
  }
  return type;
}

export function getUserId(user) {
  return (
    user?.student?.genericStudentId ||
    user?.student?.id ||
    user?.teacher?.id ||
    user?.admin?.id
  );
}
