import * as Sentry from '@sentry/nextjs';
import { errorsToIgnore } from 'sentry.server.config';

if (process.env.NODE_ENV != 'development') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ROCKALINGUA_ENVIRONMENT,
    tracesSampleRate: 0.0,
    release: process.env.NEXT_PUBLIC_BUILD_VERSION,
    beforeSend(event, hint) {
      let result = event;
      for (const error of errorsToIgnore) {
        if (
          hint.originalException?.name?.includes(error) ||
          hint.originalException?.message?.includes(error) ||
          event.exception?.values?.[0]?.value?.includes(error)
        ) {
          result = null;
        }
      }
      return result;
    },
  });
}
