import { createContext } from 'react';
import useRealTimeActivityContext from './useRealTimeActivityContext.hook';

export const RealTimeActivityContext = createContext({});

export function RealTimeActivityContextProvider({ children }) {
  const { state, actions } = useRealTimeActivityContext();
  return (
    <RealTimeActivityContext.Provider value={{ state, actions }}>
      {children}
    </RealTimeActivityContext.Provider>
  );
}
