import { useState } from 'react';

export default function useCustomHook({
  defaultState,
  actions: customHookActions,
}) {
  const [state, setState] = useState(defaultState);
  const actions = {
    resetState: (initialState = {}) => {
      setState({ ...defaultState, ...initialState });
    },
    setState: (newState) => {
      setState((previousState) => ({ ...previousState, ...newState }));
    },
    ...customHookActions,
  };

  return { state, actions };
}
