import { createContext } from 'react';
import useSSOContext from './useSSOContext.hook';
import StudentAlertPermissions from './StudentAlertPermissions/StudentAlertPermissions';

export const SSOContext = createContext({});

export function SSOContextProvider({ children }) {
  const { state, actions } = useSSOContext();
  return (
    <SSOContext.Provider value={{ state, actions }}>
      {children}
      {state.showStudentAlert ? (
        <StudentAlertPermissions onConfirm={actions.confirmRedirect} />
      ) : null}
    </SSOContext.Provider>
  );
}
