import Colors from '@/constants/Colors';
import HexOpacities from '@/constants/HexOpacities';
import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  &.success {
    background-color: white;
    border-color: white;
    color: ${Colors.success};
  }

  &.error {
    background-color: white;
    border-color: white;
    color: ${Colors.error};
    text-shadow: none;
  }

  &.outlined {
    background-color: transparent;
    border-color: ${Colors.textDark};
    color: ${Colors.textDark};
    text-shadow: none;

    &:hover {
      border-color: ${Colors.text};
      color: ${Colors.text};
    }
  }

  &.dark {
    background-color: ${Colors.text};
    border-color: ${Colors.text};

    &:hover {
      background-color: ${Colors.text}${HexOpacities[80]};
    }

    &:disabled {
      color: white;
      background-color: ${Colors.text}${HexOpacities[20]};
      border-color: ${Colors.text}${HexOpacities[20]};
    }
  }

  &.ant-btn-secondary {
    background-color: transparent;
  }

  &.important {
    background-color: ${Colors.pink};
    border-color: ${Colors.pink};
    color: white;

    &:hover {
      background-color: ${Colors.pink}${HexOpacities[80]};
    }
  }

  &.white {
    background-color: white;
    border-color: white;
    color: ${Colors.text};
    text-shadow: none !important;

    &:hover {
      background-color: ${Colors.backgroundPaleBlue}${HexOpacities[90]};
      border-color: ${Colors.backgroundPaleBlue}${HexOpacities[90]};
    }
    &:disabled {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;
