export const punctuation = [
  ',',
  '.',
  '¿',
  '?',
  '¡',
  '!',
  ';',
  ':',
  '...',
  '…',
  '-',
  '(',
  ')',
  '"',
  "'",
];

/**
 * Capitalize
 * @param {String} word
 * @returns {String}
 */
export const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const capitalizeAllWords = (sentence) =>
  sentence
    .split(/\s+/)
    .map((word) => capitalize(word))
    .join(' ');

/**
 * Concatenate strings in words leaving a space between them, except for words that are punctuation.
 * @param {*} words - array of words
 * @returns {String}
 */
export const phraseFromStrings = (words) =>
  words
    .map((w, index) => (punctuation.includes(words[index + 1]) ? w : w + ' '))
    .join('')
    .trim();

export function truncateObjectString(objStr, maxLength = 100) {
  if (objStr.length > maxLength) {
    const truncated = objStr.substring(0, maxLength);
    const truncIndex = Math.max(
      truncated.lastIndexOf(','),
      truncated.lastIndexOf('}'),
      truncated.lastIndexOf(']'),
      truncated.lastIndexOf(' ')
    );
    const truncData =
      (truncIndex > 0 ? objStr.substring(0, truncIndex + 1) : truncated) +
      ' ...}';
    return truncData;
  }
  return objStr;
}
