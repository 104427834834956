import { Modal } from 'antd';
import { StyledSessionModal } from './SessionModal.styled';
import Button from '@/shared/Button/Button';

export default function SessionModal({ actions }) {
  return (
    <Modal
      title="Your session has expired"
      visible={true}
      footer={null}
      onCancel={actions.closeModal}
    >
      <StyledSessionModal>
        <p>Please click on the button below to continue.</p>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            actions.refreshSession();
          }}
        >
          Continue
        </Button>
      </StyledSessionModal>
    </Modal>
  );
}
