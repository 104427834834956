import { createContext } from 'react';
import useGlobalContext from './useGlobalContext.hook';
import SessionModal from './SessionModal/SessionModal';

export const GlobalContext = createContext({});

export function GlobalContextProvider({ children, referer }) {
  const { state, actions } = useGlobalContext({ referer });
  return (
    <GlobalContext.Provider value={{ state, actions }}>
      {children}
      {state.showSessionModal ? <SessionModal actions={actions} /> : null}
    </GlobalContext.Provider>
  );
}
