const Colors = {
  primary: '#0F77BC',
  text: '#5E748A',
  textDark: '#464646',
  textBlack: '#000000',
  textShadow: '#00228080',
  background: '#FFFFFF',
  backgroundPaleBlue: '#F3F7FB',
  backgroundPaleBlueDark: '#E5F0FB',
  secondBackground: '#fafafa',
  darkBackground: '#f5f5f5',
  border: '#CACDDB',
  disabled: '#f5f5f5',
  gray: '#8E8E8E',
  darkGray: '#303030',
  darkerGray: '#1f1f1f',
  mediumGray: '#c0c0c0',
  lightGray: '#a0a0a0',
  lighterGray: '#cbcdda',
  evenLighterGray: '#e0e0e0',
  purple: '#a84a8e',
  blue: '#00BEF0',
  lightBlue: '#99e0ff',
  lighterBlue: '#c2e4ff',
  greenishBlue: '#02A09A',
  pink: '#FF6699',
  success: '#4dc4ad',
  error: '#db5547',
  darkBlue: '#0d4171',
  green: '#4dc4ad',
  red: '#db5547',
  darkRed: '#B32D10',
  orange: '#f7a100',
  buttonGray: '#737072',

  hoverBackground: '#eef8ff',
  boxShadowGray: '#15223214',
  boxShadowBlack: '#00000014',
  darkGreen: '#35ab2c',
  darkGreenBackground: '#35ab2c33',
  lightGreen: '#95bf1f',
  invertedForeground: 'white',

  redBackground: '#e7322833',
  greenRadioBackground: '#54AA2C33',
  darkGreenBackground: '#35ab2c33',
  activityTypeColors: {
    video: '#117DC5',
    game: '#F169F4',
    song: '#62247E',
    worksheet: '#37A434',
    pictureDictionary: '#EA9A00',
    shortStory: '#5AC5C7',
    craft: '#F0473E',
    interactiveVideo: '#8DBA21',
    themes: '#A84A8E',
    realTime: '#E5BA5F',
    external: '#CCCCCC',
    comic: '#3ABEEF',
    storyWithQuestions: '#FF6699',
  },
  subscriptionStatusTagColors: {
    active: 'green',
    expired: 'red',
  },
  subscriptionPaymentStatusTagColors: {
    pending: 'blue',
    success: 'green',
    error: 'red',
  },
  subscriptionTypeTagColors: {
    family: '#FA007F',
    teacher: '#00BCEB',
    teacherPro: '#0d4171',
  },
  providerColors: {
    google: 'gold',
    classlink: 'lime',
    clever: 'geekblue',
    password: 'red',
  },
  home: {
    background1: '#a6e0e1',
    background2: '#eeeeee',
    background3: '#00a19c',
    specialColor: '#008aae',
  },
  correctness: {
    60: '#54AA2C',
    30: '#f7a100',
    0: '#e73228',
  },
  template: {
    easy: '#9FF49C',
    medium: '#FEC34E',
    hard: '#DB5547',
  },
  providerBackgrounds: {
    clever: '#436CF2',
    classlink: '#0B4D7F',
    google: '#FFFFFF',
  },
  boxShadow: `0px 1px 4px 0px rgba(0, 0, 20, 0.12)`,
};

Colors.playerControlsThemes = {
  default: {
    background: '#f0f0f030', //Colors.playerControlsBackground,
    backgroundHover: '#f0f0f070',
    foreground: Colors.darkGray,
    lightForeground: 'white',
    buttonsForeground: Colors.darkGray,
    buttonsForegroundHover: Colors.blue,
    buttonsBackground: '#f0f0f060',
    buttonsBackgroundHover: '#dadada',
    progressBarBgColor: '#f0f0f050',
    progressBarColor: Colors.blue,
    bufferBarColor: Colors.lighterGray,
    textBackground: '#f0f0f060',
    textColor: Colors.darkGray,
  },

  inverted: {
    background: '#00000050',
    backgroundHover: '#000000ff',
    foreground: Colors.lighterGray,
    lightForeground: Colors.lighterGray,
    buttonsForeground: Colors.secondBackground,
    buttonsForegroundHover: Colors.secondBackground,
    buttonsBackground: '#50505070',
    buttonsBackgroundHover: '#303030a0',
    progressBarBgColor: '#00000050',
    progressBarColor: Colors.blue,
    bufferBarColor: '#80808060',
    textBackground: '#00000060',
  },
};

export default Colors;
