import { createContext } from 'react';
import useStudentRealTimeActivityContext from './useStudentRealTimeActivityContext.hook';

export const StudentRealTimeActivityContext = createContext({});

export function StudentRealTimeActivityContextProvider({ children }) {
  const { state, actions } = useStudentRealTimeActivityContext();
  return (
    <StudentRealTimeActivityContext.Provider value={{ state, actions }}>
      {children}
    </StudentRealTimeActivityContext.Provider>
  );
}
