import axios from '@/api/axiosInstance';

export default async function updateRealTimeActivity(realTimeActivityId, data) {
  const response = await axios({
    url: `${process.env.NEXT_PUBLIC_API}/realTimeActivities/${realTimeActivityId}`,
    method: 'put',
    data,
  });
  return response.data;
}
