import useCustomHook from '@/hooks/useCustomHook';

export default function useScrollContext() {
  const { state, actions } = useCustomHook({
    defaultState: {
      scrollIds: {},
    },
    actions: {
      publicActions: {
        setScrollId: (name, scrollId) => {
          const scrollIds = {
            ...state.scrollIds,
            [name]: scrollId,
          };
          actions.setState({ scrollIds });
        },
      },
    },
  });

  return { state, actions: actions.publicActions };
}
