const storage = {};

export default function appStorage(storageType) {
  if (storageType == 'localStorage') {
    return tryLocalStorage();
  } else if (storageType == 'sessionStorage') {
    return trySessionStorage();
  } else {
    return getMemoryStorage();
  }
}

function tryLocalStorage() {
  try {
    localStorage.getItem('test');
    return localStorage;
  } catch (e) {
    console.error(e);
    try {
      sessionStorage.getItem('test');
      return sessionStorage;
    } catch (e) {
      console.error(e);
      return getMemoryStorage();
    }
  }
}

function trySessionStorage() {
  try {
    sessionStorage.getItem('test');
    return sessionStorage;
  } catch (e) {
    console.error(e);
    return getMemoryStorage();
  }
}

function getMemoryStorage() {
  return {
    getItem: (key) => storage[key],
    setItem: (key, value) => {
      storage[key] = value;
    },
  };
}
