import { createContext } from 'react';
import useThemesContext from './useThemesContext.hook';

export const ThemesContext = createContext({});

export function ThemesContextProvider({ children }) {
  const { state, actions } = useThemesContext();
  return (
    <ThemesContext.Provider value={{ state, actions }}>
      {children}
    </ThemesContext.Provider>
  );
}
