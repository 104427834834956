import Axios from 'axios';
import axiosRetry from 'axios-retry';
import {
  setupCache,
  buildWebStorage,
  buildKeyGenerator,
} from 'axios-cache-interceptor';
import { paramsSerializer } from './axiosParamsSerializer';
import appStorage from '../storage/appStorage';

let axiosInstance = Axios.create();
axiosInstance.defaults.paramsSerializer = paramsSerializer;

// Delete axios cache entries when the cache for the request is disabled. Necessary because empty entries are created even with the cache disabled
axiosInstance.interceptors.request.use((config) => {
  if (config.cache && !config.cache.enabled) {
    config.cache = false;
    axiosInstance.storage?.remove(config.id);
  }
  return config;
}, Promise.reject);

axiosRetry(axiosInstance, {
  retryDelay: (retryCount) => {
    return retryCount * 2000;
  },
  retryCondition: (error) => {
    return (
      axiosRetry.isNetworkOrIdempotentRequestError(error) ||
      (error?.config?.method == 'post' &&
        (axiosRetry.isRetryableError(error) ||
          axiosRetry.isNetworkError(error)))
    );
  },
});

export function setupAxiosCache() {
  if (!axiosInstance.defaults.cache) {
    axiosInstance = setupCache(axiosInstance, {
      // Disabled by default but configured to generate cache keys based on the request
      ttl: 0,
      override: true,
      storage: buildWebStorage(appStorage('localStorage'), 'axios-cache'),
      generateKey: buildKeyGenerator((request) => {
        return {
          method: request.method,
          url: request.url,
          params: request.params,
          query: request.query,
          data: request.data,
          custom: request.headers.Authorization,
        };
      }),
    });
  }
}

/**
 * Updates the corresponding cache entry with the response data of a request
 * made with cache.override = true
 * @param {*} response
 */
export async function updateAxiosCache(response) {
  await axiosInstance.storage.set(response.id, {
    data: {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
    },
    createdAt: Date.now(),
    state: 'cached',
    ttl: response.config.cache.ttl,
  });
}

export default axiosInstance;
