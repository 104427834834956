import { StyledButton } from './Button.styled';
import React from 'react';

const Button = React.forwardRef((props, ref) => {
  const { children, type } = props;
  const customTypes = [
    'success',
    'error',
    'outlined',
    'dark',
    'important',
    'white',
  ];

  if (customTypes.includes(type)) {
    return (
      <StyledButton
        ref={ref}
        {...props}
        type="primary"
        className={[type, props.className || ' '].join(' ')}
      >
        {children}
      </StyledButton>
    );
  }

  return (
    <StyledButton ref={ref} {...props}>
      {children}
    </StyledButton>
  );
});

Button.displayName = 'Button';

export default Button;
