import useCustomHook from '@/hooks/useCustomHook';
import { useEffect } from 'react';

export default function useFullscreenContext() {
  const { state, actions } = useCustomHook({
    defaultState: {
      isFullscreen: false,
    },
    actions: {
      publicActions: {
        setFullscreen: (isFullscreen) => {
          actions.setState({
            isFullscreen,
          });
        },
      },
    },
  });

  useEffect(() => {
    document.body.classList.toggle('fullscreen', state.isFullscreen);
  }, [state.isFullscreen]);

  useEffect(() => {
    function appDimensions() {
      const doc = document.documentElement;
      const maxHeight = window.innerHeight;
      const maxWidth = window.innerWidth;
      const currentRadio = maxWidth / maxHeight;
      const ratio = 16 / 10;

      if (currentRadio > ratio) {
        doc.style.setProperty('--fs-ratio-height', `${maxHeight}px`);
        doc.style.setProperty(
          '--fs-ratio-width',
          `${(maxWidth * ratio) / currentRadio}px`
        );
      } else {
        doc.style.setProperty(
          '--fs-ratio-height',
          `${(maxHeight * currentRadio) / ratio}px`
        );
        doc.style.setProperty('--fs-ratio-width', `${maxWidth}px`);
      }

      doc.style.setProperty('--appheight', `${window.innerHeight}px`);
    }
    appDimensions();
    window.addEventListener('resize', appDimensions);
    return () => {
      window.removeEventListener('resize', appDimensions);
    };
  }, []);

  return { state, actions: actions.publicActions };
}
