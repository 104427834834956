import { createContext } from 'react';
import useNavigationContext from './useNavigationContext.hook';

export const NavigationContext = createContext({});

export function NavigationContextProvider({ children }) {
  const { state, actions } = useNavigationContext();
  return (
    <NavigationContext.Provider value={{ state, actions }}>
      {children}
    </NavigationContext.Provider>
  );
}
