import getOnGoingRealTimeActivity from '@/api/realTime/getOnGoingRealTimeActivity.endpoint';
import getRealTimeActivity from '@/api/realTime/getRealTimeActivity.endpoint';
import updateRealTimeActivity from '@/api/realTime/updateRealTimeActivity.endpoint';
import useCustomHook from '@/hooks/useCustomHook';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef } from 'react';
import openAPIErrorNotification from '../APIErrorNotification/APIErrorNotification';
import { GlobalContext } from '../GlobalContext/GlobalContext';
import createRealTimeActivity from '@/api/realTime/createRealTimeActivity.endpoint';
import useRealTimeServer from '@/hooks/realTime/useRealTimeServer.hook';
import getStudentsAssignedToATask from '@/api/teacher/tasks/getStudentsAssignedToATask.endpoint';
import { WebsocketsContext } from '../WebsocketsContext/WebsocketsContext';

export default function useRealTimeActivityContext() {
  const globalContext = useContext(GlobalContext);
  const websocketsContext = useContext(WebsocketsContext);
  const router = useRouter();
  const serverActionsHandlerRef = useRef();
  const returnUrlRef = useRef();

  const { state: serverState, actions: serverActions } = useRealTimeServer({
    actionsHandler: serverActionsHandlerRef.current,
  });

  const { state, actions } = useCustomHook({
    defaultState: {
      realTimeActivity: null,
      realTimeActivityId: null,
      studentsAssignedToTask: null,
      studentItems: null,
      progressSummary: null,
      closed: false,
    },
    actions: {
      redirect: (realTimeActivity) => {
        if (globalContext.state.user?.teacher) {
          let route = null;

          if (realTimeActivity?.status == 'running') {
            route = '/teacher/liveTask';
          } else if (router.pathname.includes('/liveTask')) {
            if (returnUrlRef.current) {
              route = returnUrlRef.current;
            } else {
              route = realTimeActivity
                ? {
                    pathname: `/classes/${realTimeActivity.classId}`,
                    query: { tab: 'tasks' },
                  }
                : '/dashboard';
            }
          }
          if (route) {
            router.push(route);
          }
        }
      },
      loadRealTimeActivity: async () => {
        try {
          const realTimeActivity = await getRealTimeActivity(
            state.realTimeActivityId
          );
          actions.setState({
            realTimeActivity,
            loaded: true,
          });
          actions.redirect(realTimeActivity);
        } catch (e) {
          actions.setState({
            loaded: true,
          });
          openAPIErrorNotification({
            title: 'There was an error with the LIVE task',
            description: 'There was a problem loading the LIVE task',
            errorFeedback: e.feedbackData,
          });
        }
      },
      loadServerState: async () => {
        await serverActions.sendMessage({
          action: 'requestActivity',
          data: {
            realTimeActivityId: state.realTimeActivityId,
          },
        });
      },
      loadStudentsAssignedToTask: async () => {
        try {
          const students = await getStudentsAssignedToATask(
            state.realTimeActivity.taskId
          );
          actions.setState({
            studentsAssignedToTask: students.map((student) => ({
              id: student.id,
              name: `${student.firstName}${
                student.lastName ? ' ' + student.lastName : ''
              }`,
            })),
          });
        } catch (e) {
          if (e?.response?.status == 403) {
            actions.publicActions.finish();
          }
        }
      },
      publicActions: {
        checkExistingRealTimeActivity: async (redirect) => {
          try {
            const realTimeActivity = await getOnGoingRealTimeActivity();
            if (realTimeActivity) {
              actions.setState({
                realTimeActivity,
                realTimeActivityId: realTimeActivity.id,
                loaded: true,
              });
            } else {
              await serverActions.reset();
              actions.resetState({
                loaded: true,
              });
            }
            if (realTimeActivity?.status != 'running' && redirect) {
              actions.redirect(realTimeActivity);
            }
          } catch (e) {
            console.error(e);
            actions.setState({
              loaded: true,
            });
          }
        },
        setRealTimeActivityId: (realTimeActivityId) => {
          actions.setState({
            realTimeActivityId,
          });
        },
        run: async ({ realTimeActivityId, data }) => {
          actions.resetState();
          let realTimeActivity = null;
          if (realTimeActivityId) {
            await updateRealTimeActivity(realTimeActivityId, {
              status: 'running',
            });
            realTimeActivity = await getRealTimeActivity(realTimeActivityId);
          } else {
            realTimeActivity = await createRealTimeActivity(data);
          }
          await serverActions.reset();
          actions.setState({
            realTimeActivityId: realTimeActivity.id,
            realTimeActivity: null,
            closed: false,
          });
          await serverActions.sendMessage({
            action: 'start',
            data: {
              realTimeActivityId: realTimeActivity.id,
              classId: realTimeActivity.classId,
              teacherId: realTimeActivity.teacherId,
              studentIds: null,
            },
          });
        },
        pause: async () =>
          await updateAndClose({
            state,
            status: 'paused',
            serverActions,
            returnUrlRef,
          }),
        finish: async () =>
          await updateAndClose({
            state,
            status: 'finished',
            serverActions,
            returnUrlRef,
          }),
        updateServerState: async (fields, options) => {
          await serverActions.sendMessage({
            action: 'changeActivity',
            data: {
              realTimeActivityId: state.realTimeActivityId,
              fields,
              options,
            },
          });
        },
        updateStudent: async ({ studentId, column, value }) => {
          await serverActions.sendMessage({
            action: 'updateStudent',
            data: {
              realTimeActivityId: state.realTimeActivityId,
              studentId,
              column,
              value,
              notifyStudent: true,
            },
          });
        },
        deleteStudent: async ({ studentId }) => {
          await serverActions.sendMessage({
            action: 'deleteStudent',
            data: {
              realTimeActivityId: state.realTimeActivityId,
              studentId,
            },
          });
        },
        claimAsMainDevice: async () => {
          await serverActions.sendMessage({
            action: 'claimAsMainDevice',
            data: {
              teacherId: globalContext.state.user?.teacher?.id,
            },
          });
        },
        resetProgressSummary: () => {
          actions.setState({
            progressSummary: null,
          });
        },
      },
    },
  });

  serverActionsHandlerRef.current = {
    studentsUpdated: async (studentItems) => {
      actions.setState({ studentItems });
    },
    anotherDeviceClaimedAsMain: async () => {
      router.push('/dashboard');
    },
    progressSummaryUpdated: async (progressSummary) => {
      actions.setState({ progressSummary });
    },
  };

  useEffect(() => {
    if (globalContext.state.loaded && websocketsContext.state.connectionKey) {
      if (
        globalContext.state.user &&
        globalContext.state.user?.teacher &&
        globalContext.state.isSubscriptionActive
      ) {
        if (
          state.realTimeActivityId &&
          (!state.realTimeActivity ||
            state.realTimeActivity?.id != state.realTimeActivityId)
        ) {
          actions.loadRealTimeActivity();
        } else if (!state.realTimeActivityId && !state.closed) {
          actions.publicActions.checkExistingRealTimeActivity(true);
        } else {
          actions.setState({ loaded: true });
        }
      } else {
        serverActions.reset();
        actions.resetState({
          loaded: true,
        });
      }
    }
  }, [
    websocketsContext.state.connectionKey,
    globalContext.state.loaded,
    globalContext.state.user,
    state.realTimeActivityId,
    state.closed,
  ]);

  useEffect(() => {
    if (state.realTimeActivityId) {
      actions.loadServerState();
    }
  }, [state.realTimeActivityId]);

  useEffect(() => {
    if (
      state.realTimeActivity?.taskId &&
      !state.realTimeActivity?.data?.internalUseClass
    ) {
      actions.loadStudentsAssignedToTask();
    }
  }, [state.realTimeActivity?.taskId]);

  useEffect(() => {
    if (
      globalContext.state.user?.teacher &&
      globalContext.state.isSubscriptionActive
    ) {
      actions.publicActions.checkExistingRealTimeActivity(true);
    }
  }, [globalContext.state.user, serverState?.status]);

  return {
    state: {
      ...state,
      ...serverState,
    },
    actions: actions.publicActions,
  };
}

async function updateAndClose({ state, status, serverActions, returnUrlRef }) {
  returnUrlRef.current = state.realTimeActivity.data?.returnUrl;
  await updateRealTimeActivity(state.realTimeActivityId, {
    status,
  });
  await serverActions.sendMessage({
    action: 'stop',
    data: {
      realTimeActivityId: state.realTimeActivity.id,
      classId: state.realTimeActivity.classId,
      teacherId: state.realTimeActivity.teacherId,
    },
  });
}
