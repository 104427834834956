import useCustomHook from '@/hooks/useCustomHook';
import { useEffect } from 'react';

const silenceUrl =
  process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
  '/commonGameAssets/silence.mp3';

export default function useAudioAutoPlayerContext(playerRef) {
  const { state, actions } = useCustomHook({
    defaultState: {
      active: false,
      playerRef: null,
      player: null,
      playing: false,
      active: false,
      audioQueue: [],
      currentAudio: null,
      initAudio: silenceUrl,
      queueEndPromiseCallback: () => {},
      queueEnded: false,
      playingError: false,
    },
    actions: {
      onPlayerMounted: (playerRef) => {
        actions.setState({ playerRef });
      },
      onPlayerUnmount: () => {
        actions.setState({
          active: false,
          playerRef: null,
          player: null,
          playing: false,
          active: false,
          audioQueue: [],
          currentAudio: null,
          initAudio: silenceUrl,
          queueEnded: false,
          playingError: false,
        });
      },
      activate: () => {
        // The trick to workaround the NO AUTOPLAY browser policy is to start playing an audio loop on a user interaction and maintain the player always active until the next real audio needs to be played.
        if (state.player) {
          actions.playWithCatch();
          state.player.loop = true;
          actions.setState({
            active: true,
            playingError: false,
          });
        }
      },

      createQueue: (queue, volume = 1.0) => {
        const [currentAudio, ...audioQueue] = queue;
        actions.setState({
          currentAudio,
          audioQueue,
          playing: true,
          volume,
          queueEnded: false,
          playingError: false,
        });
      },
      play: (audioOrQueue, volume = 1.0) => {
        const queue = Array.isArray(audioOrQueue)
          ? audioOrQueue
          : [audioOrQueue];
        const [currentAudio, ...audioQueue] = queue;
        return new Promise((resolve) => {
          actions.setState({
            currentAudio,
            audioQueue,
            playing: true,
            volume,
            playingError: false,
            queueEndPromiseCallback: resolve,
          });
        });
      },
      addToQueue: (audio) => {
        const newAudios = Array.isArray(audio) ? audio : [audio];
        if (state.audioQueue.length) {
          return new Promise((resolve) => {
            actions.setState({
              queueEndPromiesCallback: resolve,
              audioQueue: [...state.audioQueue, ...newAudios],
            });
          });
        } else {
          return actions.createQueue(newAudios);
        }
      },
      onAudioDuration: (duration) => {
        actions.setState({ currentAudioDuration: duration });
      },
      onAudioEnded: (...params) => {
        if (state.audioQueue.length) {
          const [currentAudio, ...audioQueue] = state.audioQueue;
          actions.setState({ currentAudio, audioQueue, playingError: false });
        } else {
          state.queueEndPromiseCallback();
          actions.setState({
            currentAudio: state.initAudio,
            playing: false,
            queueEnded: true,
            playingError: false,
          });
        }
      },
      resume: () => {
        if (state.player) {
          actions.playWithCatch();
        }
      },
      pause: () => {
        if (state.player) {
          state.player.pause();
        }
      },
      reset: () => {
        actions.setState({
          active: false,
          playing: false,
          audioQueue: [],
          currentAudio: null,
          playingError: false,
        });
      },
      playWithCatch: async () => {
        try {
          await state.player.play();
        } catch (error) {
          actions.setState({
            playing: false,
            playingError: true,
          });
        }
      },
    },
  });

  useEffect(() => {
    actions.setState({ currentAudio: state.initAudio });
  }, [state.player]);

  useEffect(() => {
    if (state.player) {
      if (state.currentAudio != state.initAudio) {
        state.player.loop = false;
        state.player.src = state.currentAudio;
        state.player.volume = state.volume || 1.0;
        actions.playWithCatch();
      } else if (state.active) {
        state.player.loop = true;
        actions.playWithCatch();
      }
    }
  }, [state.currentAudio, state.active]);

  useEffect(() => {
    if (state.playerRef && !state.player) {
      actions.setState({
        player: state.playerRef.current,
      });
    }
  }, [state.playerRef]);

  return { state, actions };
}
