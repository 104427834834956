import * as Sentry from '@sentry/nextjs';

export const errorsToIgnore = [
  'AbortError',
  'ResizeObserver loop completed with undelivered notifications',
  'ResizeObserver loop limit exceeded',
  'Non-Error promise rejection captured with keys',
  "Failed to execute 'measure' on 'Performance'",
  'Request failed with status code 401',
  "No mark named 'beforeRender' exists",
  'Cancel rendering route',
  'Request aborted',
  'Non-Error promise rejection captured with value',
];

if (process.env.NODE_ENV != 'development') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ROCKALINGUA_ENVIRONMENT,
    tracesSampleRate: 0.0,
    release: process.env.NEXT_PUBLIC_BUILD_VERSION,
    beforeSend(event, hint) {
      let result = event;
      for (const error of errorsToIgnore) {
        if (
          hint.originalException?.name?.includes(error) ||
          hint.originalException?.message?.includes(error) ||
          event.exception?.values?.[0]?.value?.includes(error)
        ) {
          result = null;
        }
      }
      return result;
    },
  });
}
