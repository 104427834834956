export function roundToXDecimals(value, decimals = 2) {
  const multiplier = Math.pow(10, decimals);
  return Math.round(multiplier * value) / multiplier;
}

export function hasValue(num) {
  return num != null && num != undefined;
}

export function hasValidValue(num) {
  return (
    num != null &&
    num != undefined &&
    !isNaN(num) &&
    num != Infinity &&
    num != -Infinity
  );
}
