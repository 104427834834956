import axios from '@/api/axiosInstance';
import sendEvent from '../monitoring/sendEvent';
import { v4 as uuidv4 } from 'uuid';
import { getEndpoint, updateUser } from '../monitoring/monitoringData';

let requestInterceptor = null;
let responseInterceptor = null;

export function setAxiosHeader(authorization) {
  axios.defaults.headers.common['Authorization'] = authorization;
}

export function clearAxiosHeader() {
  axios.defaults.headers.common['Authorization'] = null;
}

export function setAxiosInterceptors(globalContextActions) {
  if (requestInterceptor != null) {
    axios.interceptors.request.eject(requestInterceptor);
  }
  if (responseInterceptor != null) {
    axios.interceptors.response.eject(responseInterceptor);
  }

  requestInterceptor = axios.interceptors.request.use(
    beforeRequestHandler,
    requestErrorHandler
  );

  responseInterceptor = axios.interceptors.response.use(
    responseSuccessHandler,
    responseErrorHandler(globalContextActions)
  );
}

function beforeRequestHandler(config) {
  try {
    config.transactionId =
      Date.now() + '-' + Math.random().toString().substr(2, 9);
    config.headers['transaction-id'] = config.transactionId;
    const requestTime = Date.now();
    const url = config.url
      .replace(`${process.env.API}`, '')
      .replace(`${process.env.NEXT_PUBLIC_API}`, '')
      .replace(`${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_HOST}`, '');

    const { endpoint, endpointParams } = getEndpoint(url);
    const token = axios.defaults.headers.common['Authorization'];
    updateUser(token);

    config.transaction = {
      transactionId: config.transactionId,
      endpoint,
      endpointParams,
      method: config.method,
      params: config.params,
      data:
        typeof config.data == 'string' ? JSON.parse(config.data) : config.data,
      requestTime,
    };
    sendEvent({
      type: 'REQ',
      transaction: config.transaction,
    });
  } catch (e) {
    console.error(e);
  }
  return config;
}

function requestErrorHandler(config) {
  return config;
}

function responseSuccessHandler(response) {
  try {
    const { config } = response;
    const url = config.url
      .replace(`${process.env.API}`, '')
      .replace(`${process.env.NEXT_PUBLIC_API}`, '')
      .replace(`${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_HOST}`, '');
    sendEvent({
      type: 'RES',
      transaction: {
        ...config.transaction,
        status: response.status,
      },
      result: 'OK',
    });
  } catch (e) {
    console.error(e);
  }

  return response;
}

function responseErrorHandler(globalContextActions) {
  return (error) => {
    try {
      const { config, response } = error;
      const url = config.url
        .replace(`${process.env.API}`, '')
        .replace(`${process.env.NEXT_PUBLIC_API}`, '')
        .replace(`${process.env.NEXT_PUBLIC_AWS_CLOUDFRONT_HOST}`, '');

      const transaction = {
        ...config.transaction,
        status: response.status,
        error: error || 'unknown',
        body: response.data,
      };
      sendEvent({
        type: 'ERR',
        transaction,
        result: 'ERR',
      });
    } catch (e) {
      console.error(e);
    }

    if (
      error?.response?.status === 401 &&
      !error.config.preventRedirectOnUnauthorized
    ) {
      globalContextActions.manageExpiredSession();
    }
    addFeedbackToError(error);

    return Promise.reject(error);
  };
}

function addFeedbackToError(error) {
  const transactionId = error?.config.transactionId;
  if (error.response) {
    if (error?.response?.status === 404) {
      error.feedbackData = {
        problem: 'The server could not find the resource',
        url: error.config.url,
        method: error.config.method,
        httpStatus: error.response.status,
        message: error.response.data.message,
        transactionId,
      };
    } else {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      error.feedbackData = {
        problem: 'The server responded with an error',
        url: error.config.url,
        method: error.config.method,
        httpStatus: error.response.status,
        message: error.response.data.message,
        transactionId,
      };
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    error.feedbackData = {
      problem: 'The server did not respond',
      url: error.config.url,
      method: error.config.method,
      message: error.message,
      transactionId,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    error.feedbackData = {
      problem: 'The request has an error and was not sent',
      message: error.message,
      transactionId,
    };
  }
}
