import axios from '@/api/axiosInstance';

export default async function getStudentsAssignedToATask(
  taskId,
  includeProgress = false
) {
  const response = await axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API}/teacher/tasks/${taskId}/students`,
    params: {
      includeProgress,
    },
  });
  return response.data;
}
