import styled from 'styled-components';
import Colors from '@/constants/Colors';

export const StyledAjaxErrorNotigicationDescription = styled.div`
  font-size: 120%;
  .error {
    padding: 10px;
    background: ${Colors.border};
    border-radius: 10px;
    margin-top: 8px;
    .error-prop {
      display: flex;
      gap: 8px;
      .label {
        width: 7em;
        text-align: right;
      }
    }
  }
`;
export const StyledAjaxErrorNotigicationMessage = styled.div`
  color: ${Colors.red};
`;
