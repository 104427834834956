import { createContext } from 'react';
import useWebsocketsContext from './useWebsocketsContext.hook';

export const WebsocketsContext = createContext({});

export function WebsocketsContextProvider({ children }) {
  const { state, actions } = useWebsocketsContext();
  return (
    <WebsocketsContext.Provider value={{ state, actions }}>
      {children}
    </WebsocketsContext.Provider>
  );
}
