import SSOConf from '@/constants/SSOConf';
import useLocalStorageContextHook from '@/hooks/useLocalStorageContextHook';
import { notification } from 'antd';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../GlobalContext/GlobalContext';

export default function useSSOContext() {
  const globalContext = useContext(GlobalContext);
  const router = useRouter();

  const { state, actions } = useLocalStorageContextHook({
    contextName: 'SSOContext',
    defaultState: {
      loaded: false,
      pendingRedirect: null,
      showStudentAlert: false,
      redirectParams: null,
      skipped: false,
    },
    actions: {
      publicActions: {
        setPendingRedirect: (pendingRedirect) => {
          actions.setState({
            pendingRedirect,
            skipped: false,
          });
        },
        makeRedirect: ({
          provider,
          action,
          state,
          isStudent,
          moreScopes,
          skippable,
        }) => {
          const loginUrl = SSOConf[provider].getLoginUrl({
            user: globalContext.state.user,
            redirectTo: window.location.href,
            redirectErrorTo: window.location.href,
            isStudent,
            moreScopes,
          });

          actions.setState({
            pendingRedirect: {
              where: router.asPath,
              autoRedirect: true,
              redirectUrl: loginUrl,
              action,
              state,
              skippable,
            },
            skipped: false,
          });
        },
        clearPendingRedirect: (skipped) => {
          actions.setState({
            pendingRedirect: null,
            skipped,
          });
        },
        makeRedirectWithWarning: (redirectParams) => {
          actions.setState({
            showStudentAlert: true,
            redirectParams,
          });
        },
        confirmRedirect: () => {
          const redirectParams = state.redirectParams;
          actions.setState({
            showStudentAlert: false,
            redirectParams: null,
          });
          actions.publicActions.makeRedirect(redirectParams);
        },
      },
    },
  });

  useEffect(() => {
    if (
      state.loaded &&
      !router.asPath.startsWith('/auth/callback') &&
      state.pendingRedirect?.autoRedirect
    ) {
      const { pendingRedirect } = state;
      if (pendingRedirect.where == router.asPath) {
        if (!pendingRedirect.success) {
          if (!pendingRedirect.skippable) {
            notification.error({
              message: 'Invalid account!',
              description:
                pendingRedirect.errorMessage ||
                'Your external account is not valid or is used by another user',
              duration: 0,
            });
          }
          actions.publicActions.clearPendingRedirect(pendingRedirect.skippable);
        }
      } else {
        actions.publicActions.clearPendingRedirect();
      }
    }
  }, [state.loaded, router.asPath]);

  useEffect(() => {
    if (
      state.pendingRedirect?.autoRedirect &&
      !state.pendingRedirect.success &&
      !router.asPath.startsWith('/auth/callback')
    ) {
      router.push(state.pendingRedirect.redirectUrl);
    }
  }, [state.pendingRedirect]);

  return { state, actions: actions.publicActions };
}
