/**
 * Serializes params to a query string like axios pre-v1
 *
 * {
 *   object: { a: 1 },                        // object=%7B%22a%22:1%7D
 *   arrayOfArray: [[1, 2, ","], [2]],        // arrayOfArray[]=[1,2,%22,%22]&arrayOfArray[]=[2]
 *   arrayOfNullUndefined: [null, undefined], // arrayOfNullUndefined[]=null&arrayOfNullUndefined[]=undefined
 *   arrayOfObject: [{ id: 1 }, { id: 12 }],  // arrayOfObject[]=%7B%22id%22:1%7D&arrayOfObject[]=%7B%22id%22:12%7D
 *   arrayOfString: ["a", "b", "c"],          // arrayOfString[]=a&arrayOfString[]=b&arrayOfString[]=c
 *   undefinable: undefined,                  // X
 *   nullable: null,                          // X
 *   emptyArray: [],                          // X
 *   emptyString: "",                         // emptyString=
 *   emptyObject: {},                         // emptyObject=%7B%7D
 *   date: new Date("1970-01-01"),            // date=1970-01-01T00:00:00.000Z
 * }
 *
 */
export function paramsSerializer(params) {
  return Object.entries(params)
    .filter(
      ([, value]) =>
        value !== undefined &&
        value !== null &&
        (Array.isArray(value) ? value.length > 0 : true)
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}[]=${encodeAxiosParam(v)}`).join('&');
      }
      return `${key}=${encodeAxiosParam(value)}`;
    })
    .join('&');
}

/**
 * Encodes a single query param
 */
function encodeAxiosParam(value) {
  let v = value;
  if (toString.call(value) === '[object Date]') {
    v = value.toISOString();
  } else if (typeof value === 'object') {
    v = JSON.stringify(value);
  }
  return encodeURIComponent(v);
}
