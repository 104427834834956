import axios from '@/api/axiosInstance';

export default async function createRealTimeActivity(data) {
  const response = await axios({
    url: `${process.env.NEXT_PUBLIC_API}/realTimeActivities`,
    method: 'post',
    data,
  });
  return response.data;
}
