import { Button, Modal } from 'antd';
import Image from 'next/image';

export default function StudentAlertPermissions({ onConfirm }) {
  return (
    <Modal
      visible
      title="Please, pay attention"
      footer={
        <div className="w-100 text-center">
          <Button onClick={onConfirm} type="primary" block>
            OK
          </Button>
        </div>
      }
      closable={false}
    >
      <p>Make sure your progress is shared with your teacher.</p>
      <p>
        You will see a Google Classroom page where you need to{' '}
        <strong>click continue</strong>:
      </p>
      <div className="w-100 text-center">
        <Image
          height={400}
          width={246}
          src={
            process.env.NEXT_PUBLIC_CLOUDFRONT_STATIC_HOST +
            '/img/classroomPermissions.png'
          }
          alt="Permissions screen example"
        />
      </div>
    </Modal>
  );
}
