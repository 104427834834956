import axios from 'axios';
import { getUserId, getBrowserString, getUserType } from './monitoringData';
import {
  getConnectionInfoStr,
  getCustomEventString,
  getDeviceInfoStr,
  getElapsedStr,
  getEndpointStr,
  getErrorStr,
  getLanguageStr,
  getStorageStr,
  getTransacStr,
  getUserStr,
} from './eventStrings';

const axiosInstance = axios.create();

export default async function sendEvent({ type, transaction, data, result }) {
  try {
    const env = { production: 'PROD', staging: 'STG', development: 'DEV' }[
      process.env.NEXT_PUBLIC_ROCKALINGUA_ENVIRONMENT
    ];
    const eventDate = new Date();
    const timeZoneOffset = -eventDate.getTimezoneOffset() / 60;

    const event = {
      type,
      data,
      result,
      ...(transaction ?? {}),
      env,
      date: eventDate.toISOString(),
      timeZoneOffset,
      userType: getUserType(),
      userId: getUserId(),
      browser: getBrowserString(),
    };

    const eventString = (
      {
        REQ: (e) =>
          `REQ-EV: ${getEndpointStr(e)}|${getTransacStr(e)}` +
          `|${getUserStr(e)}||${getConnectionInfoStr()}`,
        RES: (e) =>
          `RES-EV: ${getEndpointStr(e)}|${getTransacStr(e)}` +
          ` ${getElapsedStr(e)}` +
          `|${getUserStr(e)}|${e.result}(${e.status})` +
          `|${getConnectionInfoStr()}|${
            e.browser
          }|${getDeviceInfoStr()}|${getStorageStr()}|${getLanguageStr()}`,
        ERR: (e) =>
          `ERR-EV: ${getEndpointStr(e)}|${getTransacStr(e)}` +
          ` ${getElapsedStr(e)}` +
          `|${getUserStr(e)}|${getErrorStr(e)}|${getConnectionInfoStr()}|${
            e.browser
          }`,
      }[type] ?? getCustomEventString
    )(event);

    //console.log(eventString);

    await axiosInstance.post(
      `${process.env.NEXT_PUBLIC_MONITORING_API}/events`,
      eventString,
      {
        headers: { 'Content-Type': 'text/plain' },
      }
    );
  } catch (e) {
    console.log(e, type, transaction, data);
  }
}
