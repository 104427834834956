import { createContext } from 'react';
import useScrollContext from './useScrollContext.hook';

export const ScrollContext = createContext({});

export function ScrollContextProvider({ children }) {
  const { state, actions } = useScrollContext();
  return (
    <ScrollContext.Provider value={{ state, actions }}>
      {children}
    </ScrollContext.Provider>
  );
}
