import { createContext } from 'react';
import useSidebarContext from './useSidebarContext.hook';

export const SidebarContext = createContext({});

export function SidebarContextProvider({ children }) {
  const { state, actions } = useSidebarContext();
  return (
    <SidebarContext.Provider value={{ state, actions }}>
      {children}
    </SidebarContext.Provider>
  );
}
