import { BroadcastChannel } from 'broadcast-channel';

const channels = {};

export function getBroadcastChannel(name) {
  if (!channels[name]) {
    channels[name] = new BroadcastChannel(name);
  }
  return channels[name];
}
